import React,{lazy,Suspense} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch  } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Loading from "components/Loading/Loading";
//import VisitkoreaFetch from './service/visitkorea-fetch';
import Visitkorea from './service/visitkorea';
import "assets/scss/trip-kit.scss?v=1.9.0";
//언어 셋팅
import i18n from "config/lang/i18n.js";

// pages for this product
const Main = lazy(() => import('views/Main/Main'));
const TravelList = lazy(() => import('views/Travel/TravelList'));
const CultureList = lazy(() => import('views/Culture/CultureList'));
const FoodList = lazy(() => import('views/Food/FoodList'));
const HotelList = lazy(() => import('views/Hotel/HotelList'));
const ShoppingList = lazy(() => import('views/Shopping/ShoppingList'));
const FestivalList = lazy(() => import('views/Festival/FestivalList'));
const LeisureList = lazy(() => import('views/Leisure/LeisureList'));
const CourseList = lazy(() => import('views/Course/CourseList'));
const ContentsPost = lazy(() => import('views/ContentsPost/ContentsPost.jsx'));
const Privacy = lazy(() => import('views/Info/Privacy'));
const Search = lazy(() => import('views/Search/Search.jsx'));

const hist = createBrowserHistory();

let usrLang = "en";

if (navigator.language!=null) //이 값이 null이 아니면. 즉 크롬이나 파폭이면
{
  usrLang = navigator.language;

  //한국에서 처음 접속 하면 영문으로 나오게. 사이트안에서 한국어로 설정 하면 예외
  if(usrLang == "ko" || usrLang == "ko-kr" || usrLang == "ko-KR"){
    usrLang ="en";
  }

} else {
  usrLang ="en";
}
usrLang = usrLang.toLowerCase(); //받아온 값을 소문자로 변경

if(usrLang == "zh" || usrLang == "zh-cn" || usrLang == "zh-chs" || usrLang == "zh-hans" ){ //중국어 간체
  usrLang = "chs";
}else if(usrLang == "zh-cht" || usrLang == "zh-hant" || usrLang == "zh-hk" || usrLang == "zh-mo" || usrLang == "zh-sg" || usrLang == "zh-tw" ){ //중국어 번체
  usrLang = "cht";
}else {
  usrLang = usrLang.substring(0, 2); //소문자로 변경한 갚의 앞 2글자만 받아오기
}
/*일본어 */
if(usrLang == "ja"){
  usrLang ="jp";
}

let lang = localStorage.getItem('lang') || usrLang;
//const currency = localStorage.getItem('currency') || 'krw';

if(window.location.search.match("local=en")){
  lang = "en";
}else if(window.location.search.match("local=jp")){
  lang = "jp";
}else if(window.location.search.match("local=ko")){
  lang = "ko";
}else if(window.location.search.match("local=chs")){
  lang = "chs";
}else if(window.location.search.match("local=cht")){
  lang = "cht";
}else if(window.location.search.match("local=de")){
  lang = "de";
}else if(window.location.search.match("local=ru")){
  lang = "ru";
}else if(window.location.search.match("local=fr")){
  lang = "fr";
}else if(window.location.search.match("local=es")){
  lang = "es";
}

i18n.changeLanguage(lang);

const visitkorea = new Visitkorea(lang);

ReactDOM.render(
  <HelmetProvider>
  <BrowserRouter history={hist}>
  <Suspense fallback={<div style={{textAlign:"center",margin:"10px",justifyContent: "center", alignItems: "center", minHeight: "100vh",display: "flex"}}><Loading /></div>}>
    <Switch>
      <Route exact path="/" render={(props) => <Main {...props} lang= {lang}  visitkorea= {visitkorea} /*currency= {currency}*/ />} />

      <Route exact path="/travel" render={(props) => <TravelList {...props}  lang= {lang} type={"travel"} typeId={"1"} visitkorea= {visitkorea} />} />
      <Route path="/travel/:keyword/:city/:district" render={(props) => <TravelList {...props}  lang= {lang} type={"travel"} typeId={"1"} visitkorea= {visitkorea} />} />
      <Route path="/travel/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"travel"} visitkorea= {visitkorea} />} />

      <Route exact path="/culture" render={(props) => <CultureList {...props}  lang= {lang} type={"culture"} typeId={"2"} visitkorea= {visitkorea} />} />
      <Route path="/culture/:keyword/:city/:district" render={(props) => <CultureList {...props}  lang= {lang} type={"culture"} typeId={"2"} visitkorea= {visitkorea} />} />
      <Route path="/culture/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"culture"} visitkorea= {visitkorea} />} />

      <Route exact path="/food" render={(props) => <FoodList {...props}  lang= {lang}  type={"food"} typeId={"3"} visitkorea= {visitkorea} />} />
      <Route path="/food/:keyword/:city/:district" render={(props) => <FoodList {...props}  lang= {lang}  type={"food"} typeId={"3"} visitkorea= {visitkorea} />} />
      <Route path="/food/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"food"} visitkorea= {visitkorea} />} />

      <Route exact path="/hotel" render={(props) => <HotelList {...props}  lang= {lang} type={"hotel"} typeId={"4"} visitkorea= {visitkorea} />} />
      <Route path="/hotel/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"hotel"} visitkorea= {visitkorea} />} />

      <Route exact path="/shopping" render={(props) => <ShoppingList {...props}  lang= {lang} type={"shopping"} typeId={"5"} visitkorea= {visitkorea} />} />
      <Route path="/shopping/:keyword/:city/:district" render={(props) => <ShoppingList {...props}  lang= {lang} type={"shopping"} typeId={"5"} visitkorea= {visitkorea} />} />
      <Route path="/shopping/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"shopping"} visitkorea= {visitkorea} />} />

      <Route exact path="/festival" render={(props) => <FestivalList {...props}  lang= {lang} type={"festival"} typeId={"6"} visitkorea= {visitkorea} />} />
      <Route path="/festival/:keyword/:city/:district" render={(props) => <FestivalList {...props}  lang= {lang} type={"festival"} typeId={"6"} visitkorea= {visitkorea} />} />
      <Route path="/festival/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"festival"} visitkorea= {visitkorea} />} />

      <Route exact path="/leisure" render={(props) => <LeisureList {...props}  lang= {lang} type={"leisure"} typeId={"7"} visitkorea= {visitkorea} />} />
      <Route path="/leisure/:keyword/:city/:district" render={(props) => <LeisureList {...props}  lang= {lang} type={"leisure"} typeId={"7"} visitkorea= {visitkorea} />} />
      <Route path="/leisure/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"leisure"} visitkorea= {visitkorea} />} />

      <Route exact path="/course" render={(props) => <CourseList {...props}  lang= {lang} type={"course"} typeId={"8"} visitkorea= {visitkorea} />} />
      <Route path="/course/:keyword/:city/:district" render={(props) => <CourseList {...props}  lang= {lang} type={"course"} typeId={"8"} visitkorea= {visitkorea} />} />
      <Route path="/course/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"course"} visitkorea= {visitkorea} />} />
      
      <Route path="/transportation/:type/:id" render={(props) => <ContentsPost {...props} lang= {lang} type={"transportation"} visitkorea= {visitkorea} />} />

      <Route path="/privacy" render={(props) => <Privacy {...props}   lang= {lang}/>} />

      <Route path="/search/:keyword/:city/:district" render={(props) => <Search {...props} lang= {lang} contentType={"leisure"} visitkorea= {visitkorea} />} />

    </Switch>
 
  </Suspense> 
  </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);
